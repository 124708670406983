<template>


  <div class="admin_panel">
      <div class="admin_panel--left">
        <p :class="{'category__active': $route.path === '/category_control' }" @click="$router.push('category_control')">
          📂 Категории
        </p>
        <p :class="{'category__active': $route.path === '/admin_panel' }" @click="$router.push('/admin_panel')">
          🛒 Товары
        </p>
        <p :class="{'category__active': $route.path === '/stock' }" @click="$router.push('/stock')">
          📦 Склад
        </p>
        <p :class="{'category__active': $route.path === '/sales' }" @click="$router.push('/sales')">
          💰 Продажи
        </p>
        <p :class="{'category__active': $route.path === '/analytic' }" @click="$router.push('/analytic')">
          📊 Аналитика
        </p>

<!--        <p :class="{'category__active': $route.path === '/bonuses' }" @click="$router.push('/bonuses')">Бонус</p>-->

<!--        <p :class="{'category__active': $route.path === '/loans' }" @click="$router.push('/loans')">Займы</p>-->
      </div>


      <div style="width: 100%">
        <router-view></router-view>
      </div>
  </div>
</template>


<script>
export default {
  name: "AdminControl",
  data() {
    return {
    }
  },
  mounted() {
    if(!localStorage.getItem('access_token')) {
      this.$router.push('/')
    }
  },
  methods: {

  }

};
</script>

<style>

p {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.admin_panel--left {
  background: #0069ff;
  height: 100vh;
  width: 250px;
  padding: 20px;
  p {
    cursor: pointer;
    color: white;
    margin-bottom: 30px !important;
    font-weight: bold;
  }
}

.admin_panel {
  display: flex;
  flex-direction: row;
}

.category__active {
  color: #f39c12 !important;
}

</style>